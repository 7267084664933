.page{
  padding: 20px 40px;
  background-color: lightgrey;
  min-height: 100vh;
  height: 100%;
  overflow-x: hidden;
  display: block;
}
.div-flex-column{
  display: flex;
  flex-direction: column;
}
.rg-10{
  row-gap: 40px;
}
.image{
  height: 100%;
  width: 100%;
}